import ReactGA from 'react-ga';
import { useEffect } from 'react';

function Analytics() {

    useEffect(() => {
        ReactGA.initialize('G-ESM9Z3DVST');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (<></>);
}

export default Analytics;