export const updateLangState = (dispatch, state) => {
    return dispatch({
        type: "LANG_UPDATED",
        payload: state,
    });
}
export const updateTranslationState = (dispatch, state) => {
    return dispatch({
        type: "TRANSLATION_UPDATED",
        payload: state,
    });
}
export const updateCountryState = (dispatch, state) => {
    return dispatch({
        type: "COUNTRY_UPDATED",
        payload: state,
    });
}
export const updateAdminState = (dispatch, state) => {
    return dispatch({
        type: "ADMIN_UPDATED",
        payload: state,
    });
}