import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import React, { Suspense, useEffect, useContext, useState } from 'react';
import Preloader from './components/UI/Preloader/Preloader';
import { Store } from './store/store-reducer';
import { updateLangState, updateTranslationState, updateCountryState } from './store/actions';
import { getTranslation, ipLookup } from './utils/utils';
import { getCountryCallingCode } from 'libphonenumber-js';
import Popup from './components/Popup/Popup';
import pMinDelay from 'p-min-delay';

const LandingPage = React.lazy(() => pMinDelay(import('./pages/Landing/Landing'), 500));
const ProductionPage = React.lazy(() => pMinDelay(import('./pages/Production/Production'), 500));
const CatalogPage = React.lazy(() => pMinDelay(import('./pages/Catalog/Catalog'), 500));
const ItemPage = React.lazy(() => pMinDelay(import('./pages/Item/Item'), 500));
const CompactItemPage = React.lazy(() => pMinDelay(import('./pages/CompactItem/CompactItem'), 500));
const AdminPage = React.lazy(() => pMinDelay(import('./pages/Admin/Admin'), 500));


function App() {

  const { state, dispatch } = useContext(Store);
  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('lang')) {
      updateLangState(dispatch, localStorage.getItem('lang'));
    }
  }, []);


  useEffect(() => {
    async function fetchTranslation() {
      const translation = await getTranslation(state.lang);

      ipLookup()
      .then(data => {

        updateTranslationState(dispatch, { 
          ...translation, 
          header: { ...translation.header, city: data.city[state.lang] } 
        });
        updateCountryState(dispatch, getCountryCallingCode(data.country));
        setTimeout(() => {
          setLoadingState(false);
        }, 700);
      })
    }

    fetchTranslation();
  }, [state.lang]);

  return (
    <>
      <div style={{ 'opacity': isLoading ? 0 : 1 }} className="App">
          <Router>
            <Suspense fallback={<Preloader fullPage={true}/>}>
              <Routes>
                <Route exact path="/" element={<LandingPage/>}/>
                <Route exact path="/catalog/:type/" element={<CatalogPage/>}/>
                <Route exact path="/catalog/item/:id/" element={<ItemPage/>}/>
                <Route exact path="/catalog/tech/:id/" element={<CompactItemPage/>}/>
                <Route exact path="/production" element={<ProductionPage/>}/>
                <Route exact path="/admin" element={<AdminPage/>}/>
              </Routes>
            </Suspense>
            {/* <LocationHandler/> */}
          </Router>
      </div>
      {isLoading &&
        <div>
          <Preloader fullPage={true}/>
        </div>
      }
    </>
  );
}

export default App;
