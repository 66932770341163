import { createContext, useReducer } from "react";
import { getBrowserLang } from "../utils/utils";

const initialState = {
  lang: getBrowserLang(),
  countryNum: 'GE',
  translatedContent: {},
  isAdmin: undefined
}


const reducer = (state, action) => {
    switch (action.type) {
        case "LANG_UPDATED":
          return { ...state, lang: action.payload };
        case "TRANSLATION_UPDATED":
          return { ...state, translatedContent: action.payload };
        case "COUNTRY_UPDATED":
          return { ...state, countryNum: action.payload };
        case "ADMIN_UPDATED":
          return { ...state, isAdmin: action.payload };
        default:
          return { ...state };
    }
}

export const Store = createContext(initialState);
export const StoreProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
      <Store.Provider value={{ state, dispatch }}>
        {props.children}
      </Store.Provider>
    );
};