export function getBrowserLang() {
    if (navigator.languages.some(e => e.includes('ru'))) {
        return 'RUS';
    } else if (navigator.languages.some(e => e.includes('en'))) {
        return 'ENG'
    }
    return 'GEO';
}

export async function getTranslation(lang) {
    const translation = await fetch(`/api/translation?lang=${lang}`)
    .then(response => response.json());
    return translation.data;
}

export async function ipLookup() {
    const city = await fetch(`/api/iplookup`)
    .then(response => response.json());
    return city.data;
}

export async function getOffers(params) {

    const offers = await fetch('/api/get-data', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(response => response.json());
    return offers.data;
}

export async function getItem(id, type, admin) {
    const offers = await fetch('/api/get-offer', {
        method: 'POST',
        body: JSON.stringify({
            id: id,
            type: type || 'kitchen',
            admin: admin
        }),
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(response => response.json());
    return offers.data;
}

export async function sendEmail(data) {

    const formData = new FormData();
    if (data.file) {
        formData.append('file', data.file);
    }
    
    for (const key in data) {
        if (key !== 'file') {
            formData.append(key, data[key]);
        }
    }

    const response = await fetch('/api/send-email', {
        method: 'POST',
        body: formData
    })
    .then(response => response.json());
    return response;
} 