import './preloader.css';
import preloaderImg from '../../../assets/images/icons/loader.gif';

function Preloader({ fullPage }) {

    function Loader() {
        return (
            <div className="preloader__content">
                {/* <div></div><div></div><div></div><div></div> */}
                <img src={preloaderImg} alt="" />
            </div>
        );
    }

    
    if (fullPage) return (
        <div className="preloader">   
            <Loader/>
        </div>
    );

    return <Loader/>;
}

export default Preloader;